const __STORE = Symbol('store');

class Storage {
  constructor() {
    this[__STORE] = window.localStorage;
  }

  save(name, value) {
    this[__STORE].setItem(name, JSON.stringify(value));
  }

  retrieve(name) {
    try {
      const storeItem = this[__STORE].getItem(name);
      return JSON.parse(storeItem);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  remove(name) {
    this[__STORE].removeItem(name);
  }

  clear() {
    this[__STORE].clear();
  }
}

const storage = new Storage();
export default storage;
