<template>
  <div class="Reward-path">
    <div class="path">
      <div class="path-progress" :style="progressStyle"></div>
      <div class="path-ball" v-bind:style="ballStyle"></div>
    </div>

    <div class="path-info">
      <div class="start"></div>

      <div class="end">
        <div>
          Get
          <span class="remaining">{{ total - current }}</span> more entries for a reward
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserRewardPath",
  props: {
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percent() {
      return (this.current / this.total) * 100;
    },
    progressStyle() {
       const percent =
        this.percent > 100 ? 100 : this.percent < 0 ? 0 : this.percent;
      return {
        width: `calc(${percent}%)`
      };
    },
    ballStyle() {
      const percent =
        this.percent > 100 ? 100 : this.percent < 0 ? 0 : this.percent;
      return {
        left: `calc(${percent}% - 20px)`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$green: #2be22b;

.path {
  position: relative;
  height: 5px;
  border-radius: 1rem;
  background: #d8d8d8;

  &-progress {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    background: $green;
  }

  &-ball {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $green;
    transform: translateY(-50%);
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
  }
}

.remaining {
  color: $green;
  font-weight: bold;
}

.start {
  width: 40px;
  height: 40px;
  background: url(../../assets/you.svg) no-repeat center;
}

.end {
  display: inline-flex;
  align-items: center;
  padding-right: 2.75rem;
  height: 40px;
  background: url(../../assets/award.svg) no-repeat center right/contain;

  div {
    @media (max-width: 359px) {
      font-size: 12px;
    }
  }
}
</style>
