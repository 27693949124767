<template>
  <div class="Reward-list">
    <h3 class="text-center mb-5">Rewards</h3>
    <div class="row">
      <div class="col-12 col-sm-6" v-for="reward in rewards" :key="reward.title">
        <Reward
          :image="reward.reward_image_holder"
          :title="reward.title"
          :description="reward.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Reward from "./Reward";

export default {
  name: "Rewards",
  props: {
    rewards: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    Reward
  }
};
</script>
