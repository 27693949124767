<template>
  <div class="socials">
     <svg width="0" height="0" class="d-none">
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.997 299.997" id="email">
        <path
          d="M149.996 0C67.157 0 .001 67.158.001 149.997c0 82.837 67.156 150 149.995 150s150-67.163 150-150C299.996 67.158 232.835 0 149.996 0zm.003 52.686l88.763 55.35H61.236l88.763-55.35zm89.869 143.737h-.009c0 8.878-7.195 16.072-16.072 16.072H76.211c-8.878 0-16.072-7.195-16.072-16.072v-84.865c0-.939.096-1.852.252-2.749l84.808 52.883c.104.065.215.109.322.169.112.062.226.122.34.179a8.903 8.903 0 0 0 1.847.721c.065.018.13.026.195.041a9.148 9.148 0 0 0 2.093.265h.015c.7 0 1.401-.099 2.093-.265.065-.016.13-.023.195-.041a8.99 8.99 0 0 0 1.847-.721 9.19 9.19 0 0 0 .34-.179c.106-.06.218-.104.322-.169l84.808-52.883c.156.897.252 1.808.252 2.749v84.865z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.196 112.196" id="facebook">
        <circle cx="56.098" cy="56.098" r="56.098" fill="#3b5998"></circle>
        <path
          d="M70.201 58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34c0-5.964 2.833-15.303 15.301-15.303l11.234.047v12.51h-8.151c-1.337 0-3.217.668-3.217 3.513v7.585h11.334l-1.325 12.876z"
          fill="#fff"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.196 112.196" id="linkedin">
        <circle cx="56.098" cy="56.097" r="56.098" fill="#007ab9"></circle>
        <path
          d="M89.616 60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118-3.705 0-5.906 2.491-6.878 4.903-.353.862-.444 2.059-.444 3.268v22.524h-13.41s.18-36.546 0-40.329h13.411v5.715c-.027.045-.065.089-.089.132h.089v-.132c1.782-2.742 4.96-6.662 12.085-6.662 8.822 0 15.436 5.764 15.436 18.149zm-54.96-36.642c-4.587 0-7.588 3.011-7.588 6.967 0 3.872 2.914 6.97 7.412 6.97h.087c4.677 0 7.585-3.098 7.585-6.97-.089-3.956-2.908-6.967-7.496-6.967zm-6.791 59.77H41.27v-40.33H27.865v40.33z"
          fill="#f1f2f2"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.198 112.198" id="pinterest">
        <circle cx="56.099" cy="56.1" r="56.098" fill="#cb2027"></circle>
        <path
          d="M60.627 75.122c-4.241-.328-6.023-2.431-9.349-4.45-1.828 9.591-4.062 18.785-10.679 23.588-2.045-14.496 2.998-25.384 5.34-36.941-3.992-6.72.48-20.246 8.9-16.913 10.363 4.098-8.972 24.987 4.008 27.596 13.551 2.724 19.083-23.513 10.679-32.047-12.142-12.321-35.343-.28-32.49 17.358.695 4.312 5.151 5.621 1.78 11.571-7.771-1.721-10.089-7.85-9.791-16.021.481-13.375 12.018-22.74 23.59-24.036 14.635-1.638 28.371 5.374 30.267 19.14 2.133 15.537-6.607 32.363-22.255 31.155z"
          fill="#f1f2f2"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.319 291.319" id="twitter">
        <path
          d="M145.659 0c80.45 0 145.66 65.219 145.66 145.66 0 80.45-65.21 145.659-145.66 145.659S0 226.109 0 145.66C0 65.219 65.21 0 145.659 0z"
          fill="#26a6d1"
        ></path>
        <path
          d="M236.724 98.129c-6.363 2.749-13.21 4.597-20.392 5.435 7.338-4.27 12.964-11.016 15.613-19.072a71.965 71.965 0 0 1-22.55 8.366c-6.473-6.691-15.695-10.87-25.909-10.87-19.591 0-35.486 15.413-35.486 34.439 0 2.704.31 5.335.919 7.857-29.496-1.438-55.66-15.158-73.157-35.996a33.46 33.46 0 0 0-4.807 17.315c0 11.944 6.263 22.504 15.786 28.668-5.826-.182-11.289-1.721-16.086-4.315v.437c0 16.696 12.235 30.616 28.476 33.784a36.707 36.707 0 0 1-9.35 1.211c-2.285 0-4.506-.209-6.673-.619 4.515 13.692 17.625 23.651 33.165 23.925-12.153 9.249-27.457 14.748-44.089 14.748-2.868 0-5.69-.164-8.476-.482 15.722 9.777 34.367 15.485 54.422 15.485 65.292 0 100.997-52.51 100.997-98.029l-.1-4.461a70.838 70.838 0 0 0 17.697-17.826z"
          fill="#fff"
        ></path>
      </symbol>
    </svg>

    <div class="socials-container">
      <span class="socials-icon" :key="social.title" v-for="social of share">
        <a target="__blank" :href="social.href+link" :title="social.title">
          <svg>
            <use :xlink:href="'#'+social.title"></use>
          </svg>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  props: {
    share: {
      type: Array,
      default: () => [],
    },
    link: String
  }
}
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 78%;

  a {
    display: block;
    text-decoration: none;
  }

  svg {
    width: 35px;
    height: 35px;
  }

  &-container {
    display: flex;
    justify-content: center;
    height: 45px;
    margin-bottom: 10px;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 7px;
    border-radius: 10px;
    background: #f5f5f5;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>
