<template functional>
  <div class="User-rank my-5 text-center">
    <h2>You're ranked #{{ props.rank }} with {{ props.numberOfEntries }} entries</h2>
    <p>Complete the actions below, get more entries, and WIN rewards</p>
  </div>
</template>

<script>
export default {
  name: 'UserRank',
  props: {
    numberOfEntries: {
      type: Number,
      required: true,
      default: 0,
      validator(value) {
        return value >= 0;
      },
    },
    rank: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
  },
};
</script>
