<template>
  <form autocomplete="false" @submit.prevent="validateForm()">
    <header class="mb-4">
      <h3>Almost there!!!</h3>
      <p>Sign up quickly to grab the sweet pot</p>
    </header>

    <div class="form-body mb-4">
      <div class="form-group">
        <input
          name="name"
          v-validate="{required: true}"
          v-model="input.name"
          type="text"
          class="form-control auth-input"
          :readonly="loading"
          :class="errors.has('name') && 'border-danger'"
          placeholder="What's your name?"
        >
        <span v-if="errors.has('name')" class="text-danger small">{{ errors.first('name') }}</span>
      </div>

      <div class="form-group">
        <input
          v-model="input.email"
          name="email"
          v-validate="{required: true, email: true}"
          type="text"
          :readonly="loading"
          class="form-control auth-input"
          :class="errors.has('email') && 'border-danger'"
          placeholder="Your email address"
        >
        <span v-if="errors.has('email')" class="text-danger small">{{ errors.first('email') }}</span>
      </div>

      <div class="form-group">
        <input
          v-model="input.question"
          data-vv-as="security question"
          name="question"
          v-validate="{required: true}"
          type="text"
          :readonly="loading"
          class="form-control auth-input"
          :class="errors.has('question') && 'border-danger'"
          placeholder="What's your favorite meal"
        >
        <span v-if="errors.has('question')" class="text-danger small">{{ errors.first('question') }}</span>
      </div>

      <div class="form-group">
        <p class="text-black-50">
          By entering this contest, you agree to the
          <a class href="#">Terms and Condition</a>
        </p>
        <div class="form-check">
          <input
            :readonly="loading"
            v-model="input.acceptTerms"
            class="form-check-input"
            type="checkbox"
            id="terms"
          >
          <label for="terms">I Agree</label>
        </div>
      </div>

      <div class="d-flex justify-content-center mb-3">
        <div class="col-10 px-0">
          <button
            type="submit"
             :style="{backgroundColor: design.button_color, color: design.button_text_color }"
            :disabled="!input.acceptTerms || loading"
            class="btn btn-lg btn-block btn-default"
            :class="{disabled: !input.acceptTerms }"
          >Sign up</button>
        </div>
      </div>

      <p class="text-center">
        Have you signed up already?
        <a @click.prevent="toggleForm()" href="javascript:void 0">Sign in</a>
      </p>
    </div>

    <footer class="text-muted text-center small">
      <span>Please note: We'll notify you by email of your winnings</span>
    </footer>
  </form>
</template>

<script>
import { mapState } from "vuex";

const generateInput = () => ({
  name: "",
  email: "",
  question: "",
  acceptTerms: false
});

export default {
  name: "RegisterForm",
  props: {
    toggleForm: Function,
    loading: Boolean,
    error: Boolean
  },
  data() {
    return {
      input: generateInput()
    };
  },
  computed: mapState(["design"]),
  methods: {
    async validateForm() {
      const valid = await this.$validator.validate();
      if (valid) {
        this.registerUser();
      }
    },
    registerUser() {
      this.$emit("register", this.input);
    },
    resetForm() {
      this.input = generateInput();
      this.$validator.reset();
    }
  },
  watch: {
    error(value) {
      !value && this.resetForm();
    }
  }
};
</script>
