<template>
  <section class="Auth">
    <div v-if="loading" class="form-loading-container">
      <div class="form-loading"></div>
    </div>

    <transition name="fade" mode="out-in">
      <RegisterForm
        :loading="loading"
        :error="error"
        v-if="showRegister"
        :toggleForm="handleFormToggle"
        @register="handleRegister"
      />

      <LoginForm v-else :loading="loading" :toggleForm="handleFormToggle" @login="handleLogin"/>
    </transition>
  </section>
</template>

<script>
import RegisterForm from "./RegisterForm.vue";
import LoginForm from "./LoginForm.vue";
import { mapState } from "vuex";

export default {
  name: "Auth",
  components: {
    RegisterForm,
    LoginForm
  },
  props: {
    error: Boolean,
    loading: Boolean
  },
  data() {
    return { showRegister: true };
  },
  computed: mapState(["design"]),
  methods: {
    handleRegister(user) {
      this.$emit("register", user);
    },
    handleLogin(email) {
      this.$emit("login", email);
    },
    handleFormToggle() {
      this.showRegister = !this.showRegister;
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes running {
  from {
    transform: translate(-60%, 0%);
  }

  25% {
    background: #f83636;
  }

  75% {
    background: #42c6f6;
  }

  to {
    transform: translate(160%, 0%);
  }
}

.form-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #e9e9e9;
}

.form-loading {
  width: 50%;
  height: 100%;
  background: #56ea56;
  animation: running 1s infinite linear alternate;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.Auth {
  position: relative;
  display: none;
  width: 100%;
  padding: 20px;
  border-radius: 0.25rem;
  overflow: hidden;
  background: #fff;
  border: 1px solid #eaeaea;

  @media (min-width: 992px) {
    display: block;
    top: 3rem;
  }
}
</style>
