<template>
  <div class="loader-wrapper">
    <div class="loader-bg"></div>
    <div class="loader"></div>
    <p>Loading</p>
  </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes scale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes colors {
  0% {
    background-color: orangered;
  }
  25% {
    background-color: blueviolet;
  }
  50% {
    background-color: goldenrod;
  }
  75% {
    background-color: limegreen;
  }
  100% {
    background-color: lightblue;
  }
}

.loader-wrapper {
  position: fixed;
  z-index: 100999;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  perspective: 1000px;

  &::after {
    content: "";
    display: block;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: red;
    animation: colors 5s ease infinite, grow 5s ease-in infinite;
  }

  & > *:not(.loader-bg) {
    position: relative;
    z-index: 2;
  }

  p {
    margin-top: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    &::after {
      content: "...";
      display: inline-block;
      margin-left: 3px;
      transform-origin: 0 100%;
      animation: scale 0.6s ease-out infinite alternate;
    }
  }

  .loader {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    animation: spin 1s ease infinite, colors 3s ease-out infinite;
  }

  .loader-bg {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0);
    opacity: 0.8;
  }
}
</style>
