<template>
  <div class="Action-list">
    <h4 class="mb-4 text-center">Complete these actions</h4>
    <div class="mb-3" v-for="(action, i) of sortedAction" v-bind:key="i">
      <UserAction :action="action" @submit-action="handleSubmit"/>
    </div>
  </div>
</template>

<script>
import UserAction from "./UserAction";

export default {
  name: "UserActions",
  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
      required: true
    }
  },
  components: { UserAction },
  computed: {
    sortedAction() {
      const actions = [...this.actions];
      const sortedActions = [...actions].sort((a, b) => (b.completed ? -1 : 1));
      return actions.some(action => action.completed) ? sortedActions : actions;
    }
  },
  methods: {
    handleSubmit(data) {
      this.$emit("submit-data", data);
    }
  }
};
</script>
