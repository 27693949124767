import Contest from './views/Contest.vue';
import Cookie from './libs/cookie';
import ErrorPage from './views/ErrorPage.vue';
import LandingPage from './views/LandingPage.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import storage from './libs/storage';
import store from './store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/contest/:contestId',
      name: 'index',
      component: LandingPage
    },
    {
      path: '/contest/:contestId/contestant/:contestantId',
      name: 'contest',
      meta: {
        requiresAuth: true
      },
      component: Contest
    },
    {
      path: '*',
      name: '404',
      component: ErrorPage
    },
  ]
});

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth) {
    const contestId = to.params.contestId;
    const sid = Cookie.get('sid');

    if (!sid) {
      return next({ name: 'index', params: { contestId } });
    }

    if (!store.state.actionId) {
      const credentials = {
        email: storage.retrieve('email'),
        viral_contest_id: contestId
      };

      store.dispatch('initStore', contestId);
      store.dispatch('loginUser', credentials);
    }
  } else {
    storage.clear();
    Cookie.set('sid', '', 0);
  }
  next();
});

export default router;
