<template>
  <header
    class="Banner"
    :style="{ backgroundImage: `url(${design.bg_image})` }"
  >
    <div class="Banner-content">
      <img :src="design.logo" class="Banner-brand" alt="logo" />
      <h1 :style="{ color: design.heading_color }" class="Banner-title mb-5">
        {{ contest.title }}
      </h1>
      <p :style="{ color: design.subheading_color }" class="Banner-subtitle">
        {{ contest.subheading }}
      </p>
    </div>

    <slot></slot>

    <div
      v-if="design.has_overlay"
      class="Banner-overlay"
      :style="{ backgroundColor: design.bg_overlay_color }"
    />
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Banner",
  computed: mapState(["design", "contest"]),
};
</script>

<style lang="scss" scoped>
$desktop: 992px;
@mixin overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Banner {
  position: relative;
  height: 40vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin-bottom: 2.5rem;
  background: #131212 no-repeat center top/cover;

  text-align: center;

  @media (min-width: $desktop) {
    height: 450px;
  }

  &-overlay {
    @include overlay();
    background: rgba(51, 6, 6, 0.61);
  }

  &-brand {
    width: 100px;
    height: 100px;
    margin-bottom: 2rem;
  }

  &-content {
    position: relative;
    z-index: 20;
    color: #fff;

    @media (min-width: $desktop) {
      width: 400px;
    }
  }

  &-title {
    margin: 0 0 0.75rem;
    font-size: 2em;
    @media (min-width: $desktop) {
      font-size: 2.5rem;
    }
  }

  &-subtitle {
    margin: 0 0 0.5rem;
    @media (min-width: $desktop) {
      font-size: 1.4em;
    }
  }
}
</style>
