<template>
  <div class="Unexpected-error">
    <div class="Unexpected-error_body">
      <div style="width: 600px; padding: 5rem;">
        <div class="Unexpected-error_bg mb-5"></div>

        <slot>
          <h2 class="Unexpected-error_title">
            <span style="display: block; font-size: 100px">404</span>
            <span>Page Not Found</span>
          </h2>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage"
};
</script>

<style lang="scss">
.Unexpected-error {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  border: none;
  border-radius: 0.3rem;
  text-align: center;

  &_bg {
    height: 300px;
    background: transparent url("/error-contest.svg") no-repeat top center/90%;
  }

  &_title {
    color: #ff5151;
  }

  &_message {
    color: #555;
  }
}
</style>
