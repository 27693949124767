<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>


<style lang="scss">
html,
body {
  min-height: 100vh;
  font-size: 14px;
  background: #f4f4f4;
  font-family: "Open sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

img {
  width: 100%;
  max-width: 100%;
}

.auth-input {
  height: 50px;
  border: none;
  padding-left: 15px;
  background: #e7e7e7;

  &:focus {
    box-shadow: none;
  }
}
</style>
