import axios from 'axios';
import config from '../config';

const defaultApiUrl = 'http://conversion.marketgee.com/api/v1/'

let baseURL = config.app.apiConnectionUrl || defaultApiUrl;

const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export default http;
