<template functional>
  <div class="Reward">
    <h4 v-once class="text-center p-3 bg-light text-muted mb-0">{{ props.title }}</h4>

    <div :style="{backgroundImage: `url(${props.image})`}" class="Reward-img p-4"></div>

    <div class="Reward-info p-3">
      <h4 v-once class="mb-4 text-center font-weight-bold">{{ props.title }}</h4>
      <p v-once>{{ props.description.slice(0, 300) }}...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reward"
};
</script>

<style lang="scss" scoped>
@mixin overlay($z-index: 2) {
  position: absolute;
  z-index: $z-index;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.Reward {
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px 0 #dedede;
  transition: 400ms ease-in;
  overflow: hidden;

  > h4 {
    opacity: 1;
    transition: all 400ms ease 400ms;
  }

  &:hover {
    box-shadow: 0 2px 10px 5px #c3c3c3;

    > h4 {
      visibility: hidden;
      opacity: 0;
      transition: all 100ms ease;
    }

    & .Reward-info {
      visibility: visible;
      opacity: 1;
      pointer-events: none;
    }
  }

  &-img {
    height: 200px;
    background: #fff no-repeat top center/100% 100%;
    background-origin: content-box;
  }

  &-info {
    @include overlay($z-index: 4);
    // padding: 1rem;
    border-radius: inherit;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    // text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 400ms ease-in-out;
  }
}
</style>
