<template>
  <form @submit.prevent="login">
    <header class="mb-4">
      <h3>Welcome Back</h3>
      <p>Remember we will notify you by email of your wins</p>
    </header>

    <div class="form-body mb-4">
      <div class="form-group">
        <input
          name="email"
          v-validate="{required: true, email: true}"
          v-model="email"
          type="email"
          :readonly="loading"
          class="form-control auth-input"
          :class="{'border-danger': errors.has('email')}"
          placeholder="Your email address"
        >
        <span class="text-danger small" v-if="errors.has('email')">{{ errors.first('email') }}</span>
      </div>
      <div class="form-group">
        <button
          :style="{backgroundColor: design.button_color, color: design.button_text_color }"
          :disabled="errors.has('email') || !email || loading"
          type="submit"
          class="btn btn-lg btn-block btn-default"
        >Login</button>
      </div>
    </div>

    <footer class="text-center">
      <p class="mb-0">You haven't signed up yet, then what are you wait for?</p>
      <a class="btn" @click.prevent="toggleForm()" href="#">Sign up &rarr;</a>
    </footer>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LoginForm",
  props: {
    toggleForm: Function,
    loading: Boolean
  },
  data() {
    return { email: "" };
  },
  computed: mapState(["design"]),
  methods: {
    async login() {
      const isValid = await this.$validator.validate();
      if (isValid) this.$emit("login", this.email);
    }
  }
};
</script>
