<template>
  <div class="Contest">
    <Header :verify="!!user.email_verified" :title="contest.title" :subheading="contest.subheading"/>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-7">
          <UserRewardPath :current="currentPoint" :total="maximumPoints"/>
        </div>

        <div class="w-100"></div>

        <UserRank v-bind:rank="1" v-bind:numberOfEntries="currentPoint"/>

        <div class="w-100"></div>

        <div class="col-md-12 col-md-5 col-lg-5 mb-4">
          <Referral :url="shareLink" />
          <UserActions @submit-data="handleSubmitAction" :actions="userActions"/>
        </div>

        <div class="col-md-12 col-md-7 col-lg-6 ml-lg-auto">
          <Rewards v-bind:rewards="rewards"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Header from "@/components/Contest/Header";
import UserRank from "@/components/Contest/UserRank";
import UserRewardPath from "@/components/Contest/UserRewardPath";
import Referral from "@/components/Contest/Referral";
import Rewards from "@/components/Contest/Rewards";
import UserActions from "@/components/Contest/UserActions";

export default {
  name: "Contest",
  components: {
    Header,
    UserRewardPath,
    UserRank,
    Referral,
    UserActions,
    Rewards
  },
  mounted() {
    const CONTEST_ID = this.$route.params.contestId;
    const REFERRER_ID = this.$route.params.contestantId;
    const BASE_URL = window.location.origin;

    this.shareLink = `${BASE_URL}/contest/${CONTEST_ID}/share?referrer=${REFERRER_ID}`;
  },
  data() {
    return {
      shareLink: ""
    };
  },
  computed: {
    ...mapState(["user", "rewards", "userActions", "actionId", "contest"]),
    ...mapGetters(["maximumPoints", "currentPoint"])
  },
  methods: {
    ...mapActions({ completeAction: "completeUserAction" }),
    async handleSubmitAction(data) {
      const payload = {
        ...data,
        action_id: this.actionId,
        viral_contest_id: this.$route.params.contestId,
        contestant_id: this.user.contestant_uid
      };
      try {
        await this.completeAction(payload);
        this.$notify({
          type: "success",
          title: "Action Completed"
        });
      } catch (err) {
        console.log(err)
        this.$notify({
          type: "warning",
          title: "Action failed",
          message: "Something went wrong"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$green: #2be22b;

.point {
  color: $green;
}

.stick {
  position: sticky;
  top: 1rem;
}
</style>
