<template functional>
  <header class="Contest-header text-center mb-5">
    <div v-if="!props.verify" class="py-3 px-md-5 bg-warning">
      <p class="lead">We have sent you a verification mail, please go to your email address to <strong class="text-dark">verify your email</strong> and continue with the contest</p>
    </div>
    <slot>
      <div class="py-4">
        <h2 class="title mt-0 mb-3">{{ props.title }}</h2>
        <h5 class="subtitle">{{ props.subheading }}</h5>
      </div>
    </slot>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String,
    subheading: String,
    verify: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss">
.Contest-header {
  min-height: 80px;
  background: #252323;
  color: #fff;

  .subtitle {
    font-size: 1.2em;
  }
}
</style>
