import ContestService from './contest-service';
import Cookie from "./libs/cookie";
import Vue from "vue";
import Vuex from "vuex";
import storage from "./libs/storage";
import uuid4 from "uuid/v4";

const SESSION_TIMEOUT = 60;
export const SET_USER = "SET_USER";
export const SET_CONTEST = "SET_CONTEST";
export const SET_DESIGN = "SET_DESIGN";
export const SET_REWARDS = "SET_REWARDS";
export const SET_USER_ACTIONS = "SET_USER_ACTIONS";
export const SET_ACTION_ID = "SET_ACTION_ID";
export const COMPLETE_ACTION = "COMPLETE_ACTION";
export const UPDATE_CURRENT_POINT = "UPDATE_CURRENT_POINT";

const storeState = {
  contest: {},
  user: {},
  userActions: [],
  rewards: [],
  design: [],
  actionId: null
};

const getters = {
  maximumPoints: (currentState) =>
    currentState.userActions.reduce((sum, action) => sum + action.points, 0),
  currentPoint: (currentState) =>
    currentState.userActions.reduce((sum, action) => {
      if (action.completed) sum += action.points;
      return sum;
    }, 0)
};

const mutations = {
  UPDATE_CURRENT_POINT(state, point) {
    state.currentPoint = point;
  },
  SET_ACTION_ID(state, id) {
    state.actionId = id;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_CONTEST(state, contest) {
    state.contest = contest;
  },
  SET_REWARDS(state, rewards) {
    state.rewards = rewards;
  },
  SET_USER_ACTIONS(state, userActions) {
    state.userActions = userActions;
  },
  SET_DESIGN(state, design) {
    state.design = design;
  },
  COMPLETE_ACTION(state, actionTag) {
    state.userActions = state.userActions.map(action => {
      if (action.tag === actionTag) {
        action = { ...action, completed: !action.completed };
      }
      return action;
    });
  }
};

const actions = {
  async getContestLog(_, id) {
    return ContestService.getContestLog(id);
  },
  async initStore({ commit }, contestId) {
    const data = await ContestService.getContest(contestId);

    commit(SET_CONTEST, data.contest);
    commit(SET_DESIGN, data.design);
    commit(SET_REWARDS, data.rewards);
  },
  async loginUser({ commit }, credentials) {
    const user = await ContestService.login(credentials);

    commit(SET_USER, user.contestant);
    commit(SET_USER_ACTIONS, user.actions.actions);
    commit(SET_ACTION_ID, user.actions.id);
    storage.save("email", credentials.email);
    Cookie.set("sid", uuid4(), SESSION_TIMEOUT);
  },
  registerUser(_, credentials) {
    return ContestService.register(credentials);
  },
  async completeUserAction({ commit }, payload) {
    const data = await ContestService.addPoint(payload);
    commit(COMPLETE_ACTION, payload.action_tag);
    commit(UPDATE_CURRENT_POINT, data.total_points);
  }
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: storeState,
  getters,
  actions,
  mutations,
});
