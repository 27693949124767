import http from './libs/http';

export default class ContestService {
  static async register(credentials) {
    return http.post("viral-contest/contestant/register", credentials);
  }

  static async login(credentials) {
    const { data } = await http.post("viral-contest/contestant/login", credentials);
    return data.data;
  }

  static async getContestLog(id) {
    return http.post("/embed/viralcontest/logviews", { id });
  }

  static async getContest(id) {
    const { data: { data } } = await http.get(`viral-contest/${id}/contest-data`);
    const contest = Object.keys(data).reduce((curr, key) => {
      if (typeof data[key] !== 'object') {
        curr[key] = data[key];
      }
      return curr;
    }, {});

    return {
      rewards: data.viral_contest_rewards.rewards,
      design: data.viral_contest_design,
      contest,
    }
  }

  static async addPoint(action) {
    const res = await http.post("viral-contest/contestant/add-points", action);
    return res.data;
  }
}
