import Vue from 'vue';
import VeeValidate from "vee-validate";
import { Notification } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from './store';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VeeValidate);
// Vue.use(Notification);
Vue.prototype.$notify = Notification;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

