<template>
  <div class="mb-5 text-center">
    <h3 class="mb-4">
      Refer others
      <span class="point">(+3)</span>
    </h3>

    <CopyInput :link="url">Share your special URL to track referrals!</CopyInput>

    <SocialShare :link="url" :share="socials"/>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CopyInput from "./CopyInput";
import SocialShare from "./SocialShare";

export default {
  name: "Referral",
  props: {
    url: String
  },
  components: { SocialShare, CopyInput },
  computed: {
    ...mapState(['contest']),
    socials() {
      return [
        {
          title: "email",
          href: `mailto:?&subject=${this.contest.title}&body=Check this out. Might be worth signing up? `
        },
        {
          title: "facebook",
          href: "https://www.facebook.com/sharer/sharer.php?u="
        },
        {
          title: "twitter",
          href: "https://twitter.com/home?status="
        },
        {
          title: "linkedin",
          href:
            `https://www.linkedin.com/shareArticle?mini=true&title=${this.contest.title}&summary=${this.contest.subheading}&source=&url=`
        },
        {
          title: "pinterest",
          href:
           `https://pinterest.com/pin/create/button/?description=${this.contest.title}&url=`
        }
      ]
    }
  },
  methods: {
    copyReferralLink() {
      const referralInput = this.$refs.referral;
      referralInput.select();
      document.execCommand("copy");
      this.showTooltip = true;
      setTimeout(() => (this.showTooltip = false), 1400);
    }
  }
};
</script>

