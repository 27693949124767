<template>
  <div class="form-group row mx-0 align-items-baseline">
    <label v-if="label" class="w-100" for="referrals">
      <slot>{{ label }}</slot>
    </label>
    <div class="col-9 px-0">
      <input
        ref="referral"
        readonly
        id="referrals"
        :value="link"
        type="text"
        class="form-control"
      >
    </div>
    <div class="col pr-0">
      <div class="position-relative">
        <span class="copied" v-if="showTooltip">copied</span>
        <button v-on:click="copyReferralLink()" class="btn btn-block">
          <i class="fas fa-copy"></i>
          copy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyInput",
  props: {
    link: String,
    label: String,
  },
  data() {
    return {
      showTooltip: false
    };
  },
  methods: {
    copyReferralLink() {
      const referralInput = this.$refs.referral;
      referralInput.select();
      document.execCommand("copy");
      this.showTooltip = true;
      setTimeout(() => (this.showTooltip = false), 1400);
    }
  }
};
</script>

<style lang="scss" scoped>
.copied {
  position: absolute;
  left: 50%;
  bottom: -100%;
  min-width: 70px;
  padding: 5px;
  border-radius: 5px;
  background: #444;
  color: #fff;
  transform: translate(-50%, 0);

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-bottom-color: #444;
  }
}
</style>


