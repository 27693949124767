class Cookie {
  static set(cname, cvalue, mins) {
    let date = new Date();
    date.setTime(date.getTime() + mins * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  static get(cname) {
    const cookie = decodeURIComponent(document.cookie).split(";");
    let result = "";
    cname = `${cname}=`;

    for (let cvalue of cookie) {
      const ca = cvalue.trim();
      result = ca.includes(cname) ? ca.substr(cname.length, ca.length) : "";
    }
    return result;
  }
}

export default Cookie;
