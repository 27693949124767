<template>
  <div class="Action" :class="{completed: action.completed}" v-on:click.stop="f => f">
    <div class="Action-header" :class="{'bg-light': show}" v-on:click="toggleOpen()">
      <span class="Action-icon" v-bind:style="{color: action.iconColor}">
        <i class="font-weight-bold" v-bind:class="action.icon"></i>
      </span>
      <span class="Action-title font-weight-bold text-capitalize">{{ action.title }}</span>
      <span class="Action-point">+{{ action.points }}</span>
    </div>

    <div class="Action-body text-center py-4 px-3" v-if="show">
      <p class="Action-text">{{ action.description }}</p>

      <div
        class="row justify-content-center align-items-center"
        v-if="action.itunesUrl || action.playstoreUrl"
      >
        <div class="col-6" v-if="action.itunesUrl">
          <a v-bind:href="action.itunesUrl">
            <img src="https://vyper.io/itunes.png" alt>
          </a>
        </div>
        <div class="col-6" v-if="action.playstoreUrl">
          <a v-bind:href="action.playstoreUrl">
            <img src="https://vyper.io/googleplay.png" alt>
          </a>
        </div>
      </div>

      <a
        role="button"
        v-if="action.button"
        v-bind:href="action.url || 'javascript:void 0'"
        class="btn btn-lg btn-block btn-secondary contest-btn text-capitalize mb-4"
      >{{ action.button }}</a>

      <div class="form-group" v-if="action.question">
        <form v-on:submit.prevent="submitAction(action)">
          <p>
            <strong>{{ action.question }}</strong>
          </p>
          <input v-model="input" type="text" class="form-control">
          <button type="submit" class="btn btn-lg text-uppercase mt-4">sumbit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAction",
  mounted() {
    this.$root.$on("close-user-action", uid => {
      if (this.uid !== uid) this.show = false;
    });
    this.$root.$on("close-all-user-action", () => (this.show = false));

    document.addEventListener("click", this.closeUserAction);
  },
  destroyed() {
    document.removeEventListener("click", this.closeUserAction);
  },
  props: {
    action: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      uid: Symbol("uid"),
      input: "",
    };
  },
  methods: {
    toggleOpen() {
      this.show = !this.show;
      if (this.show) {
        this.$root.$emit("close-user-action", this.uid);
      }
    },
    closeUserAction(evt) {
      this.$root.$emit("close-all-user-action");
    },
    submitAction(action) {
      if (this.input.trim()) {
        const payload = {
          action_tag: this.action.tag,
          meta: {
            [this.action.question]: this.input,
          }
        };
        this.$emit("submit-action", payload);
        this.toggleOpen();
        this.input = "";
        return;
      }
      this.$notify({
        type: "warning",
        title: "Action input",
        message: "Please type in your answer to complete this action"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$green: #2be22b;

.Action {
  position: relative;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 3px 1rem #dedede;
  background: #fff;
  overflow: hidden;

  &.completed {
    box-shadow: none;
    opacity: 0.6;
    pointer-events: none;

    .Action-header {
      background: #e6e6e6;
    }
  }

  &-header {
    cursor: pointer;
  }

  &-text {
    font-size: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }

  %d-inline-block {
    display: inline-block;
    margin: 0 3px;
  }

  &-icon {
    @extend %d-inline-block;
    font-size: 1.5rem;
  }

  &-title {
    @extend %d-inline-block;
    font-size: 14px;
  }

  &-point {
    @extend %d-inline-block;
    font-weight: bold;
    color: $green;
  }
}

.contest-btn {
  width: 70%;
  margin: 0 auto;
  border-radius: 20px;
}
</style>
