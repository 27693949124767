<template>
  <div>
    <div
      v-if="!pageError"
      class="home"
      :style="{backgroundColor: !showLoader && design.page_color}"
    >
      <div v-if="!showLoader" class="container-fluid px-5">
        <div class="row pb-5">
          <div class="col-lg-8 col-xl-8">
            <div class="Main-content">
              <Banner clas="mb-5">
                <div class="d-lg-none banner-button">
                  <button
                    :style="{backgroundColor: design.button_color, color: design.button_text_color}"
                    data-toggle="modal"
                    data-target="#auth"
                    class="btn btn-lg contest-btn"
                  >Enter Contest</button>
                </div>
              </Banner>

              <article class="p-5">
                <section class="mb-5 pb-4" v-html="design.page_content"></section>

                <div class="row mb-5">
                  <div class="col-12 text-center mb-5">
                    <h2 class="h2 mb-0">Rewards</h2>
                  </div>

                  <div :key="reward.title" v-for="reward in rewards" class="col-md-6">
                    <div class="card pt-4">
                      <img
                        class="px-5 card-img-top mb-3"
                        :src="reward.reward_image_holder"
                        :alt="reward.title"
                      >
                      <div class="card-body">
                        <h4 class="card-title">{{ reward.title }}</h4>
                        <p class="card-text">{{ reward.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <div class="text-center d-lg-none">
                <button
                  :style="{backgroundColor: design.button_color, color: design.button_text_color}"
                  data-toggle="modal"
                  data-target="#auth"
                  class="btn contest-btn btn-lg"
                >Enter Contest</button>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-xl-4" style="transform: translate(0, 0)">
            <Auth
              :loading="authLoading"
              :error="error"
              @register="handleRegister"
              @login="handleLogin"
            />
          </div>
        </div>
      </div>

      <Loader v-if="showLoader && !pageError"/>

      <!-- Authentication modal -->
      <div id="auth" class="modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0">
              <Auth
                class="border-0"
                :loading="authLoading"
                :error="error"
                @register="handleRegister"
                @login="handleLogin"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Thank you modal -->
      <div id="thankyou" class="modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-4" style="width: 125px; margin: auto;">
                <svg
                  class="animated tada"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="100px"
                  y="100px"
                  viewBox="0 0 426.667 426.667"
                  style="enable-background:new 0 0 426.667 426.667;"
                  xml:space="preserve"
                >
                  <path
                    style="fill:#63dd63;"
                    d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"
                  ></path>
                </svg>
              </div>
              <div class="text-center">
                <h3>{{ design.thank_you_headline || "Registration complete"}}</h3>
                <p v-if="design.thank_you_message">{{ design.thank_you_message}}</p>
                <p>We'll redirect you to the contest in {{ redirectCounter }} seconds</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ErrorPage v-if="pageError">
      <h2 class="Unexpected-error_title">{{ errorTitle }}</h2>
      <p class="Unexpected-error_message">{{ errorMsg }}</p>
    </ErrorPage>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Auth from "@/components/Auth";
import Banner from "@/components/Design/Banner";
import Loader from "@/components/Loader";
import ErrorPage from "@/views/ErrorPage";

export default {
  name: "LandingPage",
  data() {
    return {
      showLoader: false,
      error: false,
      pageError: false,
      authLoading: false,
      contestId: this.$route.params.contestId,
      redirectCounter: 5,
      errorTitle: '',
      errorMsg: '',
    };
  },
  computed: mapState(["design", "rewards"]),
  methods: {
    ...mapActions({
      login: "loginUser",
      register: "registerUser",
      logView: "getContestLog",
      initContest: "initStore"
    }),
    addFavicon(url) {
      const head = document.querySelector('head');
      const link = document.createElement('link');
      link.rel = "icon"
      link.href = url;
      head.appendChild(link);
    },
    async handleRegister(user) {
      this.authLoading = true;
      const viral_contest_id = this.contestId;
      const referrer_id = this.$route.query.referral || "";
      const credentials = {
        ...user,
        viral_contest_id,
        referrer_id
      };

      try {
        await this.register(credentials);
        await this.login(credentials);
        this.authLoading = false;
        await this.showThankYouModal();
        this.redirectToContest();
      } catch (err) {
        console.error(err);
        const networkError = err.message.toLowerCase().includes("network");
        const message = networkError
          ? "Check your network"
          : "The email address might have been registered";
        this.error = true;
        this.$notify({
          type: "error",
          title: "Registration Error",
          message,
          position: "top-left"
        });
        this.authLoading = false;
      }
    },
    async handleLogin(email) {
      this.authLoading = true;
      const viral_contest_id = this.$route.params.contestId;
      const userDetails = { email, viral_contest_id };

      try {
        await this.login(userDetails);
        this.redirectToContest();
      } catch (err) {
        console.log(err);
        this.$notify({
          type: "warning",
          title: "Login Error",
          message: "Error logging you in...",
          position: "top-left"
        });
      }
      this.authLoading = false;
    },
    async showThankYouModal() {
      $("#thankyou").modal("show");
      await this.countdown();
    },
    countdown() {
      const DURATION = 1000;
      return new Promise(resolve => {
        const vm = this;
        let tid = setTimeout(function countdown() {
          vm.redirectCounter -= 1;

          if (vm.redirectCounter !== 0) {
            tid = setTimeout(countdown, DURATION);
          } else {
            resolve(clearTimeout(tid));
          }
        }, DURATION);
      });
    },
    hideModals() {
      $("#thankyou").modal("hide");
      $("#auth").modal("hide");
    },
    redirectToContest() {
      this.hideModals();
      const {
        user: { contestant_uid }
      } = this.$store.state;

      this.$router.push({
        name: "contest",
        params: {
          contestId: this.contestId,
          contestantId: contestant_uid
        }
      });
    }
  },
  async created() {
    this.showLoader = true;
    try {
      await this.initContest(this.$route.params.contestId);
      this.addFavicon(this.design.favicon);
    } catch (err) {
      this.pageError = true;
      console.error(err);

      if (err.response) {
        const status = err.response.status;
        if (status >= 400 && status < 500) {
          this.errorTitle = 'Contest Not Found';
          this.errorMsg = 'This contest does not exist or has been removed. Get a new link from contest owner';
        } else {
          this.errorTitle = 'Something Wrong Happened';
          this.errorMsg = "Sorry it is not you, We will fix it soonest for you";
        }
      }
    } finally {
      setTimeout(() => (this.showLoader = false), 1000);
    }
  },
  async mounted() {
    $("#auth").modal({ backdrop: "static", show: false });
    try {
      await this.logView(this.contestId);
    } catch (err) {
      console.error(err)
    }
  },
  components: {
    Auth,
    Banner,
    Loader,
    ErrorPage
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
}

.Main-content {
  padding-bottom: 2rem;
  border-radius: 0.25rem;
  background: #fff;

  @media (min-width: 992px) {
    margin: 0;
    margin-top: -1.5rem;
  }
}

.contest-btn {
  width: 250px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.banner-button {
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 14px;

  @media (min-width: 992px) {
    display: none;
  }
}

.modal .Auth {
  display: block;
}

.card-img-top {
  height: 150px;
  object-fit: contain;
}
</style>
